// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-us-about-us-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/about-us/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-about-us-js" */),
  "component---src-pages-contact-us-contact-us-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/contact-us/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-contact-us-js" */),
  "component---src-pages-contact-us-contact-us-presentation-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/contact-us/ContactUsPresentation.js" /* webpackChunkName: "component---src-pages-contact-us-contact-us-presentation-js" */),
  "component---src-pages-faq-faq-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/faq/Faq.js" /* webpackChunkName: "component---src-pages-faq-faq-js" */),
  "component---src-pages-faq-faq-instance-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/faq/FaqInstance.js" /* webpackChunkName: "component---src-pages-faq-faq-instance-js" */),
  "component---src-pages-index-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-difference-bullet-point-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/our-difference/BulletPoint.js" /* webpackChunkName: "component---src-pages-our-difference-bullet-point-js" */),
  "component---src-pages-our-difference-our-difference-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/our-difference/OurDifference.js" /* webpackChunkName: "component---src-pages-our-difference-our-difference-js" */),
  "component---src-pages-our-services-our-services-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/our-services/OurServices.js" /* webpackChunkName: "component---src-pages-our-services-our-services-js" */),
  "component---src-pages-sections-intro-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/sections/Intro.js" /* webpackChunkName: "component---src-pages-sections-intro-js" */),
  "component---src-pages-sections-price-indicator-index-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/sections/price-indicator/index.js" /* webpackChunkName: "component---src-pages-sections-price-indicator-index-js" */),
  "component---src-pages-sections-price-indicator-price-indicator-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/sections/price-indicator/price-indicator.js" /* webpackChunkName: "component---src-pages-sections-price-indicator-price-indicator-js" */),
  "component---src-pages-testimonials-testimonial-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/testimonials/Testimonial.js" /* webpackChunkName: "component---src-pages-testimonials-testimonial-js" */),
  "component---src-pages-testimonials-testimonial-carousel-js": () => import("/Users/andrewoh/Projects/personal/pristine-clean/src/pages/testimonials/TestimonialCarousel.js" /* webpackChunkName: "component---src-pages-testimonials-testimonial-carousel-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/andrewoh/Projects/personal/pristine-clean/.cache/data.json")

